import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const ProjectManagementPage: React.FC = () => {
  return (
    <>
      <SEO
        title="Construction project management software & app | SIGNAX"
        description="SIGNAX is an online construction project management program. We provide well-established software tools for construction management not only in Dubai but also in the UAE."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          {`The leader of construction management\n software in the UAE`}
        </Typography>
        <Typography variant="body1">
          Each construction project should meet budget limitations, be completed
          within the established timeline and achieve Client’s requirements.
        </Typography>
        <Typography variant="body1">
          Each one involves a great deal of stakeholders: designers,
          contractors, engineers, consultants and work force who have to work
          synchronized together.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/project-management/image-1.png"
            alt="Unified interface for users and support"
            title="Unified interface for users and support"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Digital tools come to help construction projects to meet its goals.
          Effective construction management tools should include remote
          construction site access and online tracking of performed works. They
          should link with transparent and precise progress data and provide
          real-time common environment for all project participants. Task and
          change management, scopes check-up and construction quality acceptance
          reporting are also among the basic requirements for construction
          management ERP.
        </Typography>
        <Typography variant="body1">
          SIGNAX is a set of IT products for CONTECH, which empower and expand
          Autodesk products functionality for full-scale BIM uses implementation
          on the site. Construction process initial data collecting, 3D
          visualization of completed works and plan-fact weekly log, amount of
          works that need to be done and timeline forecast for it, risk and
          safety notifications are already in SIGNAX!
        </Typography>
        <Typography variant="h2" color="blue">
          Step 1
        </Typography>
        <Typography variant="body1">
          SIGNAX Digital Construction Management begins from arranging smart
          data cards and setting Personal Data Area in smartphone or laptop to
          track all key indicators of construction online: works progress,
          actual status, costs and project risks. This digital data environment
          can be used like a construction project dashboard for Investor,
          Developer, Contractor or Consultant.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/project-management/image-2.jpg"
            alt="Collect construction site real-time data"
            title="Construction data DASHBOARD cards"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Step 2
        </Typography>
        <Typography variant="body1">
          Works real-time data is synced with construction BIM model. Project
          manager can see the scope of works from concrete, steel structures up
          to pipes and fittings and check its completeness.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/project-management/image-3.jpg"
            alt="Bim project real-time data in web"
            title="Online project management software for construction"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Live 360 panoramic photos are ready for remote construction site
          monitoring and availability checking. The site photo log provides full
          history of changes and opportunity to check-up completed works at any
          fixed points and time periods.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/project-management/image-4.jpg"
            alt="Bim project real-time data in web"
            title="Online project management software for construction"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Step 3
        </Typography>
        <Typography variant="body1">
          All scope of works data is fixed in smart data cards and allows
          stakeholders to compare total planned volume for each contractor,
          actual amount in chosen period (week, month, quarter) and works to be
          done according to the schedule in future.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/solutions/project-management/image-5.jpg"
            alt="Bim project real-time data in web"
            title="Online project management software for construction"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Step 4
        </Typography>
        <Typography variant="body1">
          Plan-actual comparison of works can be added with progress data and
          time forecast of each contractor to finish. The whole project schedule
          performance status visualizes with total execution percentage.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/solutions/project-management/image-6.jpg"
            alt="Bim project real-time data in web"
            title="Online project management software for construction"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Step 5
        </Typography>
        <Typography variant="body1">
          As a result of construction site digital analysis, project manager can
          use smart data cards with costs of executed, accepted and paid works
          compared with costs of remaining works; identify the sum of paid
          advances, credited advances, guarantee payments and deductions divided
          by scopes, contracts or objects.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/solutions/project-management/image-7.jpg"
            alt="Bim project real-time data in web"
            title="Online project management software for construction"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Step 6
        </Typography>
        <Typography variant="body1">
          SIGNAX Digital Construction Management technologies accelerate all
          site processes and drive project’s team efficiency to 100%. SIGNAX
          reduces time & costs for coordination, control and project meetings;
          guarantees precise real-time construction data transparency for
          stakeholders; brings remote 360-degree site monitoring right to
          managers smartphones.
        </Typography>
        <Typography variant="body1">
          Cost effective and competitive real-estate development business now
          means maximum usage of digital tools for construction coordination,
          budget control and safety risks radical decrease.
        </Typography>
        <Typography variant="body1">
          Start your own digital construction management right now!
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default ProjectManagementPage
